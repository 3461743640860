.App {
  text-align: center;
  min-height: 100vh;
  background: linear-gradient(0deg, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.25) 100%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.currency-icon-history {
  width: 24px;
}

.currency-icon {
  width: 24px;
  margin-right: 8px;
  padding-bottom: 2px;
}

.currency-icon-medium {
  width: 20px;
  margin-right: 6px;
  padding-bottom: 2px;
}

.currency-icon-small {
  width: 16px;
  margin-right: 4px;
}

.qr-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.qr-video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quickscan-summary-panel {
  max-width: 400px;
  min-width: 370px;
}

.swap-panel {
  max-width: 500px;
  min-width: 370px;
  width: calc(100% - 2rem);
}

.font-bigger {
  font-size: 1.25em;
}
.font-medium {
  font-size: 1.125em;
}
.font-small {
  font-size: 0.875em;
}
.font-smallest {
  font-size: 0.75rem;
}

.swap-direction-wrapper {
  margin-top: -12px;
  margin-bottom: -12px;
  z-index: 100;
}

.swap-direction-btn {
  width: 50px;
  height: 50px;
}

.no-wallet-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0.25) 100%);
}

.height-50 {
  height: 50%;
}

.mb--8 {
  margin-bottom: -8px;
}

.mb--6 {
  margin-bottom: -6px;
}

.line-height-100 {
  line-height: 100% ;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.dottedUnderline { border-bottom: 1px dotted; }

.text-decoration-dotted {
  text-decoration-style: dotted;
  text-decoration-line: underline;
}

.centered-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}